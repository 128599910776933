/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ExpandableImage from '../../components/ExpandableImage';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "In February of 2018, on a sunny morning on the Island of Oahu, we hiked the Wiliwilinui Ridge all the way to its highest point. We enjoyed the spectacular view of both the North and South sides of Oahu’s coastline from the Island’s narrow point on its Eastern side. For two moderately active, irregular hikers, the Ridge was no easy feat. Overall, it was about 2.5 hours of hiking: 1.5 hours of moderate hiking, and the remainder a tougher hike with a steep descent or ascent."), "\n", React.createElement(_components.p, null, "In order to access Wiliwilunui, we had to first go through a gated community, Waialae Iki, which is near Honolulu. We first spoke to the guard at the guard house, who explained the hike and provided a parking pass. Fortunately, the guard was kind enough to offer us a ride up to the trail head, so we avoided a 15 minute hike up the street to the beginning of the dirt track."), "\n", React.createElement(ExpandableImage, {
    image: props.data.mdx.frontmatter.images[0].childImageSharp.gatsbyImageData
  }), "\n", React.createElement(_components.p, null, "The first portion of the hike was mostly flat. We meandered through lush, tropical forest for a time before beginning on a ridge. Even this early in the hike, there were spectacular valley views. As it had rained the day before, the sticky red mud clung to our hiking boots. We had some issues with gripping the trail but it did not prevent us from making the ascent."), "\n", React.createElement(ExpandableImage, {
    image: props.data.mdx.frontmatter.images[1].childImageSharp.gatsbyImageData
  }), "\n", React.createElement(_components.p, null, "We came around a bend and saw the ridge ascend up to an electrical station. I recalled that this was a milestone on the hike, so I told Bear that the station was our destination. We were both getting a bit tired, so we exerted ourselves to reach the station in hopes that it would be the pinnacle of the hike. I was slightly ahead of Bear. I finally came up the muddy wooden steps to the flat area where the station was located. I looked across where the path continued: the path appeared to descend somewhat, and ascend steeply to what I now knew was the actual end of the hike. I turned around to see Bear's mouth agape: it was not over yet."), "\n", React.createElement(_components.p, null, "Finally, after much huffing and puffing up the final stretch, we were welcomed with the strong gusts of salty wind on our cheeks. Lush, moss green cliffs and sharp ridges extended in every direction around us, reaching toward aqua blue waters. The skyline seemed to be buffed by hazy white clouds far on the horizon. I think we stood there for thirty minutes just breathing it all in."), "\n", React.createElement(ExpandableImage, {
    image: props.data.mdx.frontmatter.images[2].childImageSharp.gatsbyImageData
  }), "\n", React.createElement(_components.p, null, "Our descent went by much more quickly than our trip up the mountain. Half way down, we were blessed with a gentle, skin-cooling rain shower. We were both blown away at how nice that felt after such an exerting hike. We wandered back down through the gated community and day dreamed about living there one day. We each picked our favourite homes and picked features we would copy if we were to build our own home. Maybe someday."), "\n", React.createElement(_components.p, null, "Though it was not easy, we both felt that it was a hike well-worth doing. Check the weather forecast and go prepared - and of course, do not forget your camera."), "\n", React.createElement(ExpandableImage, {
    image: props.data.mdx.frontmatter.images[3].childImageSharp.gatsbyImageData
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
